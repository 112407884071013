import { render, staticRenderFns } from "./B31s.vue?vue&type=template&id=0ce9619a&scoped=true&"
import script from "./B31s.vue?vue&type=script&lang=js&"
export * from "./B31s.vue?vue&type=script&lang=js&"
import style0 from "./B31s.vue?vue&type=style&index=0&id=0ce9619a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce9619a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VCard,VCol,VContainer,VFadeTransition,VFooter,VHover,VIcon,VImg,VOverlay,VResponsive,VRow,VSlideYReverseTransition})
